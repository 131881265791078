import styled from "@emotion/styled"

export const StyleSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.marginTop};
  padding: ${props => props.Padding};
  min-height: ${props => props.SectionMinHeight};
  background: ${props => props.Gbackground ? props.Gbackground : props.background};

  @media screen and (max-width: 600px) {
    background: ${props => props.displayGradientResponsive ? props.Gbackground : props.background};
    margin-top: 0 !important;
    padding-top: 0 !important;

    & > div {
      padding-top: ${props => props.needPTopResp ? "8vh" : "0"};
    }
  }

  @media screen and (max-width: 900px) {
    background: ${props => props.displayGradientResponsive ? "" : props.background} !important;
  }
`

export const SectionWrapper = styled.div`
  width: ${props => props.isFirstSection ? "100%" : "80%"};
  height: ${props => props.isFirstSection ? "100%" : "80%"};
  margin: ${props => props.marginTop ? props.marginTop : "auto"} auto auto auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 600px) {
    margin-top: 0 !important;
  }


`
