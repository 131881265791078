export const INDEX_TOOLTIPS = [
  "Focus DataScience",
  "Our Solutions",
  "Download Our Guide",
  "Services",
  "Your Study Within 4 Steps",
  "Your Study, But Easy",
  "Save Your Energy",
  "Free Guides",
  "They Trust Us",
  "They Trust Us",
  "Start Here",
  "Contact Us"
]

export const DATA_MANAGEMENT_TOOLTIPS = [
  "Data Management",
  "Download Our Guide",
  "Your Study Within 4 Steps",
  "Your Study, Without Anxiety",
  //"YOU WON'T BE OVERWHELMED",
  "No More Waste",
  "Free Guides",
  "They trust us",
  "Start Here",
  "Contact Us"
]

export const BIO_STATISTICS_TOOLTIPS = [
  "Biostatistics",
  "Your Study Within 4 Steps",
  "Compliancy",
  "Our Tools",
  "They Trust Us",
  "Start Here",
  "Contact Us"
]


export const QUALITY_ASSURANCE_TOOLTIPS = [
  "Quality Assurance",
  "Our Services",
  "Norms & Compliancy?",
  "Testimonials",
  "They Trust Us",
  "Start Here",
  "Contact Us"
]

export const ABOUT_US_TOOLTIPS = [
  "Home",
  "About Us",
  "About The Founder",
  "Our Services",
  "Our Commitments",
  "Download Our Guide",
  "They trust us",
  "Start Here",
  "Contact Us"
]

export const CONTACT_US_TOOLTIPS = [
  "Get Your Quote",
  "Contact Us"
]

export const REFERENCES_TOOLTIPS = [
  "Testimonials",
  "Sponsors"
]

export const DEFAULT_START_HERE_TOOLTIPS = [
  "Download Our Guide",
  "Start Here",
]

export const BEGGINER_START_HERE_TOOLTIPS = [
  "Download Our Guide",
  "Start Here",
  "Get Your Answers With An Expert",
  "Why You Need Data Management",
  "Your Study Within 4 Steps",
  "Necessary Documents",
  "Start Here",
  "Contact Us"
]

export const ADVANCED_START_HERE_TOOLTIPS = [
  "Download Our Guide",
  "Start Here",
  "Consulting Sessions",
  "Contact Us"
]

export const DATA_MANAGEMENT_BLUEPRINT_TOOLTIPS=[
  "Quote",
  "Consulting Sessions",
  "They trust us"
]

export const DATA_MANAGEMENT_OVERSIGHT_TOOLTIPS = [
  "Oversight",
  "Your study on track",
  "They trust us",
  "Start Here",
  "Contact Us"
]