import React from "react"
import { SectionWrapper, StyleSection } from "./StyledSection"

export default function Section({
                                  SectionMinHeight,
                                  GradientBackground,
                                  Background = `#ffffff`,
                                  children,
                                  Padding,
                                  autoHeight,
                                  marginTop,
                                  isFirstSection = false,
                                  IsResponsiveGradient = true,
                                }) {
  return (
    <StyleSection
      Gbackground={GradientBackground}
      background={Background}
      displayGradientResponsive={IsResponsiveGradient}
      SectionMinHeight={SectionMinHeight}
      Padding={Padding}
      marginTop={marginTop}
      className={autoHeight ? "section fp-auto-height" : "section"}
      needPTopResp={!isFirstSection}
    >
      <SectionWrapper
        marginTop={marginTop}
        autoHeight={autoHeight}
        isFirstSection={isFirstSection}
      >
        {children}
      </SectionWrapper>
    </StyleSection>
  )
}
