import React from "react"

import { css } from "@emotion/react"
import TopBar from "./topBar"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Menu from "./menu"

export default function FixedMenuWrapper({ background ,location}) {
  const data = useStaticQuery(graphql`
    {
      logoWhite: file(relativePath: { eq: "main-logo-white.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
       logoBlue: file(relativePath: { eq: "main-logo-blue.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }            
    }
  `)

  const scrollToTop= (event)=>{
    if(location.href === event.currentTarget.href){
      window.fullpage_api.moveTo(1, 0)
    }
  }
  return (
    <header
      style={{
        width: "100%"
      }}
      css={background === "white" ? LightMenuStyles:DarkMenuStyles}>

      <TopBar />
      <div id="navbar" className={"fixed"}>
        <div id="navbar-content-ctn" className="gutter">
          <Link onClick={(event)=>scrollToTop(event)} to="/">
            <div id="logo">

              {background === "white" ?
                <Img
                  fluid={data.logoBlue.childImageSharp.fluid}
                  alt="Focus Datascience logo"
                />
                :

                <Img
                  fluid={data.logoWhite.childImageSharp.fluid}
                  alt="Focus Datascience logo"
                />
              }

            </div>
          </Link>
          <Menu menuColor={background}  scrollToTop={scrollToTop}/>
        </div>
      </div>
    </header>
  )
}


const LightMenuStyles = css`
  h1 {
    color: #0c0c3d;
    text-transform: uppercase;
    font-weight: 500;
    margin: 8rem 0 0 0;
    padding-bottom: 6rem;
    font-size: 3.1584rem;
    text-align: center;
  }
  #navbar.fixed{
    display:block!important;
    position:fixed;
    z-index:2;
    left:0;
    top:5vh;
  }
  #navbar {
    width: 100%;
  }
  .scrolled .submenu{
    background-color:white;
  }
 
  .arrow-icon{
    fill:#0c0c3d!important;
  }
  
  #navbar-content-ctn {
    max-width: 78.2525204rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #navbar-content-ctn > a {
    position: relative;
    left: -0.72rem;
  }

  #navbar-content-ctn.scrolled-ctn {
    flex-direction: row !important;
    height: auto !important;
  }
  #logo {
    width: 11.52rem;
    display: block;
  }

  #header-content-ctn {
    max-width: 78.2525204rem;
    margin: auto;
    display: flex;
    align-items: center;
  }

  #header-content-ctn img {
    max-width: 100%;
    display: block;
  }

  .scrolled {
    position: fixed !important;
    border-bottom: 0.0625rem solid #0c0c3d !important;    
    display:flex!important;
    flex-direction:column;
    animation:slideDown .7s;
    background-color: white !important;
    top:0 !important;
    #logo{
        width:8rem!important;
    }
  }

  @media (max-width: 1023px) {
    h1 {
      text-align: center;
    }

    #navbar {
      position: fixed;
      top: 0;
      border-bottom: 0.0625rem solid #0c0c3d;
      z-index: 1;
      animation:none !important;
      top:0!important;
    }
    #navbar-content-ctn{
        flex-direction: row!important;
        background-color: white;
    }

    #header-content-ctn {
      flex-direction: column;
      padding-top: 10rem;
    }

    #header-content-ctn p {
      text-align: center;
    }

    #logo {
      width: 8rem;
    }
  }


  @media (max-width: 720px) {
    #navbar{
        top:0!important;
    }
    #navbar-content-ctn{
        flex-direction: row!important;
    }
    nav span,nav a{
      color: rgb(12, 12, 61)!important;
    }
}
  @media (max-width: 479px) {
    #header-content-ctn {
      padding-top: 9rem;
    }
  }
  @media (max-height: 1000px) {
    #navbar.fixed{
      top :6vh;
    }
  }


`

const DarkMenuStyles = css`

  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    margin: 8rem 0 0 0;
    padding-bottom: 6rem;
    font-size: 3.1584rem;
    text-align: center;
  }
  #navbar.fixed{
    display:block!important;
    position:fixed;
    z-index:2;
    top:10vh;
    left:0;
  }

  #navbar {
    width: 100%;
  }

  #navbar-content-ctn {
    max-width: 78.2525204rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  #navbar-content-ctn > a {
    position: relative;
    left: -0.72rem;
  }

  #navbar-content-ctn.scrolled-ctn {
    flex-direction: row !important;
    height: auto !important; 
  } 

  .scrolled .submenu{
    background-color:#100d24 ;
  }
  #logo {
    width: 11.52rem;
    display: block;
  }

  .scrolled {
    position: fixed !important;
    top: 0 !important;
    z-index: 1 !important;
    display:flex!important;
    flex-direction:column;
    height:auto !important;
    background-color: #100d24 !important;
    border-bottom: 0.0625rem solid #e2e3e6 !important;
    animation:slideDown .7s;
    #logo{
        width:8rem!important;
    }
  }
  @media (max-width: 1023px) {
    h1 {
      text-align: center;
    }

    #navbar {
      position: fixed;
      top: 0;
      border-bottom: 0.0625rem solid #e2e3e6;
      z-index: 1;
      animation:none !important;
      top:0!important;
    }
    nav span{
      color: white!important;
    }
    #navbar-content-ctn {
      background-color: #100d24;
      flex-direction: row!important;
    }

    #header-content-ctn {
      flex-direction: column;
      padding-top: 10rem;
    }

    #header-content-ctn p {
      text-align: center;
    }

    #logo {
      width: 8rem;
    }
  }

  @media (max-width: 479px) {
    #header-content-ctn {
      padding-top: 9rem;
    }
  }
  @media (max-height: 1000px) {
    #navbar.fixed{
      top :6vh;
    }
  }

`
