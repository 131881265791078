import React from "react"
import { Helmet } from "react-helmet"
import ReactFullpage from "@fullpage/react-fullpage"
import FixedMenuWrapper from "../components/Bar/fixedMenuWrapper"
import styled from "@emotion/styled"


export default function FullPageLayout({
                                         children,
                                         options,
                                         SEOOptions,
                                         location,
                                         scrollOverflowValue = true,
                                         background = "blue"
                                       }) {

  function fixNavbar(origin, destination) {
    if (window !== undefined) {
      const navbarElt = document.getElementById("navbar")
      const navbarEltCtn = document.getElementById("navbar-content-ctn")
      if (navbarElt !== null) {
        if (destination.index > 0) {
          navbarElt.classList.add("scrolled")
          navbarEltCtn.classList.add("scrolled-ctn")
        } else {
          setTimeout(() => {
            navbarElt.classList.remove("scrolled")
            navbarEltCtn.classList.remove("scrolled-ctn")
          }, 500)
        }
      }
    }
  }

  return (
    <>
      <FixedMenuWrapper background={background} location={location} />
      <ReactFullpage
        {...options}
        // debug
        navigation
        scrollOverflow={scrollOverflowValue}
        afterResize={() => window.fullpage_api.reBuild()}
        afterResponsive={() => window.fullpage_api.reBuild()}
        showActiveTooltip
        onLeave={fixNavbar}
        licenseKey={process.env.GATSBY_FULLPAGE_LICENSE_KEY}
        responsiveWidth={900}
        normalScrollElements={".scroll-ctn"}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <Helmet>
                <meta charSet="utf-8" />
                {SEOOptions.description !== undefined && <meta name="description" content={SEOOptions.description} />}
                <title>{SEOOptions.title}</title>
              </Helmet>
              <StyledBackground>
                {children}
              </StyledBackground>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </>
  )
}

const StyledBackground = styled.div`
  background-image: url("/contact-background.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto 320vh;

  @media (max-width: 760px) {
    background-size: 320vh 600vh !important;
  }

  @media (min-width: 1900px) {
    background-size: 200vh 220vh !important;
  }

  @media (min-width: 2300px) {
    background-size: 100vw 250vh !important;
  }
`



